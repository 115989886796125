import "./App.css";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import SharedLayoutAdmin from "./pages/admin/SharedLayoutAdmin";
import Login from "./pages/authentification/Login";
import Welcome from "./pages/admin/Welcome";
import Entreprise from "./pages/admin/Entreprise";
import Commandes from "./pages/admin/Commandes";
import CartesAbonnes from "./pages/admin/CartesAbonnes";
import CarteAbonneDetails from "./pages/admin/CarteAbonneDetails";
import Tickets from "./pages/admin/Tickets";
import SharedLayoutAbonnee from "./pages/abonnee/SharedLayoutAbonnee";
import AbonneDetails from "./pages/abonnee/AbonneDetails";
import AbonneeApercue from "./pages/abonnee/AbonneeApercue";
import BDisplayPublicPage from "./pages/abonnee/BDisplayPublicPage";
import PasswordRecovery from "./pages/authentification/PasswordRecovery";
import CreateTicket from "./pages/admin/CreateTicket";
import TicketDetails from "./pages/admin/TicketDetails";
import NotFoundPage from "./pages/NotFoundPage";
import MonCompte from "./pages/admin/MonCompte";
import ComingSoon from "./pages/error/ComingSoon";
import CommandeDetails from "./pages/admin/CommandeDetails";
import CreerCommande from "./pages/admin/CreerCommande";
import Settings from "./pages/admin/Settings";
import Pages from "./pages/admin/Pages";
import PageDetails from "./pages/admin/PageDetails";
import PageServices from "./pages/admin/PageServices";
import ServiceDetails from "./pages/admin/ServiceDetails";
import BdisplayStats from "./pages/admin/BdisplayStats";
import PagesGroupes from './pages/admin/PagesGroupes.js'
import ResetPassword from './pages/authentification/ResetPassword'
import Unauthorized from "./pages/error/Unauthorized";
import { useEffect, useState } from "react";
import Api from "./api/Api";
import { Urls } from "./api/Urls";
import CreateGroup from "./pages/admin/CreateGroup.jsx";
import CadminForm from "./pages/admin/CadminForm.jsx";
import CreateType from "./pages/admin/CreateType.jsx";
import Dashboard from "./pages/admin/Dashboard.js";
import LinkStatsDetails from "./pages/admin/LinkStatsDetails.jsx";
import BrochureStatsDetails from "./pages/admin/BrochureStatsDetails.jsx";
import ContactStatsDetailsPerType from "./pages/admin/ContactStatsDetailsPerType.jsx";
import LinkStatsDetailsPages from './pages/admin/LinkStatsDetailsPages.jsx';
import API from "./api/Api_1_3.js";
import Typography from '@mui/material/Typography'
import ProfileDetails from './pages/admin/ProfileDetails';
import CollaboratorContacts from './pages/admin/CollaboratorContacts'
import BCRM from "./pages/admin/BCRM.js";
import BATS from "./pages/admin/BATS.js";
import BSRM from "./pages/admin/BSRM.js";
import ProConsumer from './pages/abonnee/ProOrConsumerDetails'
import AbonneeProfiles from "./pages/abonnee/AbonneeProfiles.js";
import AbonneeProfilesCollaborator from "./pages/abonnee/AbonneeProfilesCollaborator.js";
import ProfileDetailsCollaborator from "./pages/admin/ProfileDetailsCollaborator.js";
import ProfileDetailsProsumer from "./pages/abonnee/ProfileDetailsProsumer";

function App() {
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState()
  const [user, setUser] = useState()
  const [roleLoading, setRoleLoading] = useState(true); 
  const userObj = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    setUser(userObj);
    setLoading(false);
      fetchUserRole()
  }, []);
  let timer;
  const logoutTime = 30 * 60 * 1000;

  // Démarre le minuteur
  function startTimer() {
    timer = setTimeout(logout, logoutTime);
  }

  function resetTimer() {
    clearTimeout(timer);
    startTimer();
  }

  function logout() {
    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${userObj?.access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
        }
        // console.log(error.response.data.message);
      });
  }
  const fetchUserRole = async () => {
    if(userObj?.access_token){
      try {
        const response = await API.get('/user/who-am-i', {
          headers: { "content-type": "application/json", authorization: `Bearer ${userObj?.access_token}` },
        });
        const userRole = response.data?.user?.role;
        setRole(userRole)
        if(userRole !== userObj?.user?.role){
          logout()
        }
      } 
      catch (error) {
        console.error("Error fetching user role:", error);
      }
      finally {
        setRoleLoading(false);
      }
    }
    else {
      setRoleLoading(false);
    }
  };


  window.addEventListener("mousemove", resetTimer);

  startTimer();
  if (loading || roleLoading) {
    return (
      <div style={{
        height:'100vh',
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}>
        <Typography variant="h4" color="initial">Loading..</Typography>
      </div>
    ); 
  }
  return (
    <>
      {loading === false && (
        <Routes>
          <Route path="" element={<Navigate to="login" />} />
          <Route path="/" element={<Outlet />} />
          <Route path="mot-de-passe-oublier" element={user?.access_token && role === "COLLABORATOR" ? <Navigate to="/abonnee" replace /> : user?.access_token && role === "CLIENT_ADMIN" ? <Navigate to="/admin" /> : user?.access_token && (role === "PROSUMER" || role === "CUSTOMER") ? <Navigate to="/account" /> : <PasswordRecovery />} />
          <Route path="reset-password-for-the-first-time" element={user?.access_token && role === "COLLABORATOR" ? <Navigate to="/abonnee" replace /> : user?.access_token && role === "CLIENT_ADMIN" ? <Navigate to="/admin" /> : user?.access_token && (role === "PROSUMER" || role === "CUSTOMER") ? <Navigate to="/account" /> : <ResetPassword />} />
          <Route path="login" element={user?.access_token && role === "COLLABORATOR" ? <Navigate to="/abonnee" replace /> : user?.access_token && role === "CLIENT_ADMIN" ? <Navigate to="/admin" /> : user?.access_token && (role === "PROSUMER" || role === "CUSTOMER") ? <Navigate to="/account" replace /> : <Login />} />
          <Route path="/admin" element={user?.access_token && role === "CLIENT_ADMIN" ? <SharedLayoutAdmin /> : <Navigate to="/login" />}>
            <Route index element={<Welcome />} />
            <Route path="/admin/settings" element={<Settings />} />

            {/* gestion des utilisateurs */}
            <Route path="mon-entreprise">
              <Route index path="/admin/mon-entreprise" element={<Entreprise />} />
            </Route>

            {/* gestion du compte */}
            <Route path="mon-compte">
              <Route index path="/admin/mon-compte" element={<MonCompte />} />
            </Route>

            {/* Gestion des cartes abonnés */}
            <Route path="gestion-des-cartes">
              <Route index path="/admin/gestion-des-cartes" element={<CartesAbonnes />} />
              <Route path="/admin/gestion-des-cartes/:id" element={<CarteAbonneDetails />} />
            </Route>

            {/* Gestion des commandes */}
            <Route path="gestion-des-commandes">
              <Route index path="/admin/gestion-des-commandes" element={<Commandes />} />
              <Route index path="/admin/gestion-des-commandes/:id" element={<CommandeDetails />} />
              <Route index path="/admin/gestion-des-commandes/creer-commande" element={<CreerCommande />} />
            </Route>
            <Route path="/admin/coming-soon" element={<ComingSoon />} />
            <Route path="/admin/contacts/BCRM" element={<BCRM />} />
            <Route path="/admin/contacts/BATS" element={<BATS />} />
            <Route path="/admin/contacts/BSRM" element={<BSRM />} />

            <Route path="/admin/pages" element={<Pages />} />
            <Route path="/admin/page" element={<PageDetails />} />{/*create*/}
            <Route path="/admin/page/:page" element={<PageDetails />} />{/*update*/}
            <Route path="/admin/:page/services" element={<PageServices />} />
            <Route path="/admin/page/:page/service" element={<ServiceDetails />} />{/*create*/}
            <Route path="/admin/page/:page/service/:service" element={<ServiceDetails />} />{/*update*/}
            <Route path="/admin/page/:page/statistics" element={<BdisplayStats />} />{/*get stats*/}
            <Route path="/admin/groups" element={<PagesGroupes />} />
            <Route index path="/admin/groups/:id" element={<CreateGroup />} />
            <Route index path="/admin/groups/add" element={<CreateGroup />} />
            <Route index path="/admin/types/:id" element={<CreateType />} />
            <Route index path="/admin/types/add" element={<CreateType />} />
            <Route path="/admin/dashboard" element={<Dashboard/>}/>
            <Route path="/admin/stats/links" element={<LinkStatsDetails/>}/>
            <Route path="/admin/stats/links/pages" element={<LinkStatsDetailsPages/>}/>
            <Route path="/admin/stats/brochures" element={<BrochureStatsDetails/>}/>
            <Route path="/admin/stats/contacts" element={<ContactStatsDetailsPerType/>}/>
            <Route path="/admin/user/:userId/profiles/:profileId" element={<ProfileDetails />} /> 
            

            {/* Service apres vente */}
            <Route path="service-apres-vente">
              <Route index path="/admin/service-apres-vente" element={<Tickets />} />
              <Route index path="/admin/service-apres-vente/creer-ticket" element={<CreateTicket />} />
              <Route path="/admin/service-apres-vente/:id" element={<TicketDetails />} />
            </Route>


            {/* route caché excell*/}
          </Route>
          <Route path="mot-de-passe-oublier" element={user?.access_token && role === "COLLABORATOR" ? <Navigate to="/abonnee" replace /> : user?.access_token && role === "CLIENT_ADMIN" ? <Navigate to="/admin" /> : <PasswordRecovery />} />
          <Route path="reset-password-for-the-first-time" element={user?.access_token && role === "COLLABORATOR" ? <Navigate to="/abonnee" replace /> : user?.access_token && role === "CLIENT_ADMIN" ? <Navigate to="/admin" /> : <ResetPassword />} />
          <Route path="login" element={user?.access_token && role === "COLLABORATOR" ? <Navigate to="/abonnee" replace /> : user?.access_token && role === "CLIENT_ADMIN" ? <Navigate to="/admin" /> : <Login />} />

          {/* Company admin Form */}
          <Route index path="/company/create" element={<CadminForm />} />
          {/* Routes client abonnee */}
          <Route path="/abonnee" element={user?.access_token && role === "COLLABORATOR" ? <SharedLayoutAbonnee /> : <Unauthorized />}>
            <Route index element={<AbonneDetails />} />
            <Route path="/abonnee/profiles" element={<AbonneeProfilesCollaborator />} />
            <Route path="/abonnee/:userId/profiles/:profileId" element={<ProfileDetailsCollaborator />} /> 
            <Route path="/abonnee/contacts" element={<CollaboratorContacts />} />
            <Route path="/abonnee/stats" element={<Dashboard />} />
          </Route>
          {/* route Prosumer, consumer */}
          <Route path="/account" element={user?.access_token && (role === "PROSUMER" || role === 'CUSTOMER') ? <SharedLayoutAbonnee /> : <Unauthorized />}>
            <Route index element={<ProConsumer />} />
            <Route path="/account/contacts" element={<CollaboratorContacts />} />
            <Route path="/account/profiles" element={<AbonneeProfiles />} />
            <Route path="/account/:userId/profiles/:profileId" element={<ProfileDetailsProsumer />} /> 
          </Route>
          <Route index path="/preview/:id" element={<AbonneeApercue />} />
          <Route path="/bdisplay/:page" element={<BDisplayPublicPage />} />{/*public*/}
          <Route index path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
