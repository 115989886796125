import { Container } from "@mui/material";
import React from "react";
import qrIconin from "../../imgs/qrcodeinvalide.png";
import { useState, useEffect } from "react";
import { Box, Button, Typography, Avatar, useMediaQuery, Switch } from "@mui/material";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import bdigitall from "../../imgs/BDIGITALL.svg";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FillProfile from "../admin/FillProfile";
import AbonneeApercueForProsumer from "./AbonneeApercuForProsumer";
import AbonneeApercueDefault from "./AbonneeApercuDefault";
import API from "../../api/Api_1_3";
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/material/styles";


function AbonneeApercue() {
  //const { user } = useAuthContext();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [role,setRole] = useState('')
  const [data,setData] = useState()
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false);
  const [errornotfound, setErroNotFound] = useState(false);  

  //on page load (load user details)
  useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setData(response.data.user);
        setRole(response.data?.user?.role)
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 405) {
          setError(true);
          setLoading(false);
        }
        if (error.response.status === 404) {
          setErroNotFound(true);
          setLoading(false);
        }
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
      });
  }, []);

  //open modal
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  //share
  const share = () => {
    var browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "edge";
        case agent.indexOf("edg") > -1:
          return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "firefox";
        case agent.indexOf("safari") > -1:
          return "safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());
    //console.log(browser);
    const url = data.url_qrcode;
    const shareData = {
      title: "Partage de code QR",
      url: `${window.location.href}`,
      text: "Venez consulter mon profil sur BdigitAll !",
    };
    if (
      navigator.share &&
      navigator.canShare(shareData) &&
      browser !== "chromium based edge (dev or canary)" &&
      window.location.href.split("/")[0] === "https:"
    ) {
      navigator.share(shareData);
    } else {
      navigator["clipboard"].writeText(`${shareData.url}`);
      toast.success("Le lien a été copié !");
    }
  };

  const showForm = () =>{
    setOpenForm(!openForm)
  }

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else return "";
  }
  // funciton to get the image on base64
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const getvcf = () => {
    window.open(data?.url_vcf_file, "download");
  };

  const [form, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    function:"",
    phone: "",
    company_name: "",
  });

  const [progress, setProgress] = useState(0);

  const checkEmptyProperties = (obj) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] === '' || obj[key] === null) {
          setIsError(true)
          return ; // Stop checking and return true if any property is empty string or null
        }
      }
    }
    setIsError(false)
    return false; // Return false if no property is empty string or null
  };

  async function onSubmitContact(values, { resetForm }) {
    const url = window.location;
    const data = {};
    const validatedData = {
      phone: values.phone,
      last_name: values.last_name,
      first_name: values.first_name,
    };
  
    checkEmptyProperties(validatedData);
  
    if (values.email === "" || values.email === null) {
      delete values.email;
    }
  
    if (values.company_name === "" || values.company_name === null) {
      delete values.company_name;
    }
  
    if (values.function === "" || values.function === null) {
      delete values.function;
    }
  
    setSending(true);
  
    let response ;
    try {
      if(value === false){
      response =  await Api.post((Urls.ADD_CONTACTS), {
          id: url.pathname.split("/")[2],
          ...values,
        });
      }
      else if(value === true) {
       response = await API.post(('/contacts/store-existing-user-as-contact'), {
          id: url.pathname.split("/")[2],
          email:values.email,
        });
      }
        
  
      if (response.status === 200) {
        toast.success("vous avez ajouté votre contact!");
        setProgress(0);
        resetForm();
      }
  
      setOpenForm(false);
    } catch (error) {
      if(value === true){
        setError(error.response.data.message)
      }
      toast.error(error.response.data.message);
      setSending(false);
    } finally {
      setSending(false);
    }
  }
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isError,setIsError] = useState(false)
  const [ErrorMsg,setErrorMsg] = useState('')

  const modalStyle = {
    position: 'absolute',
    top: isSmallScreen ? '50%' : '50%',
    left: isSmallScreen ? '50%' : '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90vw' : '400px', // Adjust the width for small screens
    maxWidth: '500px', // Add a maximum width for larger screens
    padding: '1.5rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [value, setValue] = useState(false);

  const handleChangeTab = (event) => {
    setValue(event.target.checked);
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#964CFC',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));


  const getImageStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = 110;
      height = 110;
    } else if (isMdScreen) {
      width = 120;
      height = 120;
    } else if (isLgScreen) {
      width = 135;
      height = 135;
    }

    return {
      width,
      height,
      objectFit: "cover",
    };
  };

  const getAvatarStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = 100;
      height = 100;
    } else if (isMdScreen) {
      width = 110;
      height = 110;
    } else if (isLgScreen) {
      width = 115;
      height = 115;
    }

    return {
      width,
      height,
      objectFit: "cover",
    };
  };
  const getProfilePictureStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = '140px';
      height = '140px';
    } else if (isMdScreen) {
      width = '200px';
      height = '200px';
    } else if (isLgScreen) {
      width = '200px';
      height = '200px';
    }

    return {
      width,
      height,
      objectFit: "contain",
      objectPosition: "50% 50%",
    };
  };

  return (
    <>
      { 
        // render either fill form or the public profile
        (data?.first_name && data?.last_name) === null 
        ?
          <FillProfile id={data?.id}/>
        :
        <Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {loading === false ? (
        <>
          {error === false && errornotfound === false ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              <Container
                sx={{
                  minHeight: "100%",
                  display: "flex",
                  alignItems: "center",
                  alignSelf: 'center',
                  justifyContent: 'center',
                  padding:{
                    xs: 0,
                    md: 0,
                    sm: 0,
                    lg: 0,
                  },
                  margin:{
                    xs: 0,
                    md: 0,
                    sm: 0,
                    lg: 0,
                  },
                }}
                maxWidth="md"
              >
                {
                  role === 'PROSUMER'
                  ? <AbonneeApercueForProsumer/>
                  : <AbonneeApercueDefault/>
                }
              </Container>
            </Box>
          ) 
          
          : (
            <Box>
              <Container
                sx={{
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                    sm: "center",
                    lg: "center",
                  },
                }}
                maxWidth="sm"
              >
                <img
                  style={{ margin: "1rem 0rem" }}
                  src={bdigitall}
                  alt="bdigitall logo"
                />
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#F9F6FF",
                      borderRadius: "2rem",
                      padding: "3rem",
                    }}
                  >
                    <Typography
                      marginBottom="1rem"
                      sx={{ textAlign: "center" }}
                    >
                      {error ? "Carte Inactive" : "Aucun utilisateur trouvé"}
                    </Typography>
                    <Avatar
                      sx={{
                        position: "relative",
                        borderRadius: "20px",
                        width: "12rem",
                        height: "11rem",
                        border: "1px solid #C9C5D2",
                        marginBottom: "1rem",
                      }}
                      variant="rounded"
                    >
                      {qrIconin ? (
                        <img
                          alt="icon"
                          src={qrIconin}
                          style={{
                            width: "12rem",
                            objectFit: "fill",
                            objectPosition: "50% 50%",
                          }}
                        />
                      ) : null}
                    </Avatar>
                  </Box>
                </Box>
                <a
                  href="https://www.bdigitall.co"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button sx={{ margin: "1rem 0rem" }} variant="primary">
                    Redirection vers notre site
                  </Button>
                </a>
              </Container>
            </Box>
          )}
        </>
      ) : null}
        </Box>
      }
    </>
  );
}

export default AbonneeApercue;
