import React, { useState } from "react";
import { Button, Typography, Modal, Container, List, ListItem, useMediaQuery, Collapse, TextField, Box, Tabs, Tab, CircularProgress, FormControl, Switch, Checkbox } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { styled } from '@mui/material/styles';
import MzErrorText from "../../components/ui/MzErrorText";
import { ExpandLess, ExpandMore, Add } from "@mui/icons-material";
import { ContactStyle } from './ContactModalStyle';
import '../../pages/abonnee/style/SharedLayout.css';
import Api from "../../api/Api";
import { colors } from '../../theme/Colors';
import API from "../../api/Api_1_3";
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material"; // Add Select and MenuItem imports
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as Yup from 'yup'; // Add Yup import

function ContactModal({ title, message, refuseLabel, onSuccess, onError, children, service_id, clickedButton, ...props }) {
  const [contactData, setContactData] = useState({
    service_id: service_id,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    function: "",
    school: "",
    pre_notification: ""
  });

  const [contactDataEmail, setContactDataEmail] = useState({
    service_id: service_id,
    email: "",
  });
  const [policy, setPolicy] = useState(false);
  const [sending, setSending] = useState(false)
  const [isError, setIsError] = useState(false);
  const [ErrorMsg, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('')

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [isCollapsed, setIsCollapsed] = useState(true);

  const checkEmptyProperties = (obj) => {
    const requiredFields = ["first_name", "last_name", "phone", 'email'];
    for (const key in obj) {
      if (requiredFields.includes(key) && (obj[key] === '' || obj[key] === null)) {
        setIsError(true);
        return true;
      }
    }
    setIsError(false);
    return false;
  };  

  const handleSendStats = async (id) => {
    const data = {
      service_id: id,
      type_action: clickedButton === 'CONSULT_URL' ? 'CONSULT_URL' : 'DOWNLOAD_BROCHURE'
    };
    try {
      const response = await Api.post(`/bdisplay/services/store-consultation`, data, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
      if (response.status === 200) {
        console.log('success !');
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleSubmit(values, { resetForm }) {
    setSending(true);
    setIsError(false);
    setError('');
  
    try {
      let data;
      if (value === false) {
        data = {
          contact_group: values.contact_group,
          service_id,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email || undefined,
          company: values.company_name || undefined,
          function: values.function || undefined,
          school: values.school || undefined,
          pre_notification: values.pre_notification || undefined,
        };
      } else {
        data = {
          contact_group: values.contact_group,
          service_id,
          email: values.email,
          school: values.school || undefined,
          pre_notification: values.pre_notification || undefined,
        };
      }
  
      const endpoint = value ? "/contacts/store-existing-user-as-contact" : "/bdisplay-contacts";
      const api = value ? API : Api;
  
      const response = await api.post(endpoint, data, {
        headers: {
          "Content-Type": "application/json"
        },
      });
  
      if (response.status === 200) {
        toast.success("Vous avez ajouté votre contact !");
        await handleSendStats(service_id);
        onSuccess();
        setTimeout(() => {
          props.onClose();
        }, 1000);
        resetForm();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Une erreur s'est produite. Veuillez réessayer.";
      setIsError(true);
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setSending(false);
    }
  }


  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [value, setValue] = useState(false);
  const handleChangeTab = (event) => {
    setValue(event.target.checked);
  };
  
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#964CFC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const modalStyle = {
    position: 'absolute',
    top: isSmallScreen ? '50%' : '50%',
    left: isSmallScreen ? '50%' : '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90vw' : '400px', // Adjust the width for small screens
    maxWidth: '500px', // Add a maximum width for larger screens
    padding: '1.5rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '90vh', // Set a maximum height for the modal
    overflowY: 'auto', // Enable vertical scrolling
  };

  const validationSchema = Yup.object().shape({
    contact_group: Yup.string().required('Groupe de contact est requis'), // Add validation for contact_group
    first_name: Yup.string().required('Prénom est requis'),
    last_name: Yup.string().required('Nom est requis'),
    email: Yup.string().email('Email invalide').optional(),
    phone: Yup.string().required('Numéro de téléphone est requis'),
    company_name: Yup.string().optional(),
    function: Yup.string().optional(),
    brochure: Yup.mixed().optional(),
  });


  return (
      <Modal open={props.open} onClose={props.onClose}>
        <Container>
          <Formik
                    enableReinitialize={true}
                    initialValues={{
                      contact_group: contactData.contact_group,
                      email: contactData.email,
                      function:contactData.function,
                      last_name: contactData.last_name,
                      first_name: contactData.first_name,
                      phone: contactData.phone,
                      company_name: contactData.company_name,
                      school: contactData.school,
                      pre_notification: contactData.pre_notification,
                      policy: policy,
                    }}
                    onSubmit={(values, actions) => {
                      handleSubmit(values, actions);
                    }}
                  >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                        <Box
                          style={modalStyle}
                        >
                          {/* title + close icon */}
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="h6" fontWeight={400}>
                              {title}
                            </Typography>
                            <Box onClick={props.onClose} sx={{cursor:'pointer'}}>
                              <CloseIcon/>
                            </Box>
                          </Box>

                          <Box mt={2} mb={3} >
                          <FormControl>
                            <Box display={'flex'} gap={1} alignItems={'center'}>
                              <IOSSwitch
                                checked={value}
                                onChange={handleChangeTab}
                                inputProps={{ 'aria-label': 'iOS style switch' }}
                              />
                              <Typography variant='subtitle1' sx={{fontSize:'.9rem'}} id="value">Déja Client BdigitAll</Typography>
                            </Box>
                            </FormControl>
                          <ErrorMessage name="value" component={MzErrorText} />
                          </Box>

                          <Box 
                            marginRight="1rem" 
                            sx={{ display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap: 1,
                            }}
                          >
                            <label style={{ fontSize: 15, color: colors.carbon_black }}>Groupe de contact*</label>
                            <Field
                              name="contact_group"
                              as={Select}
                              variant="outlined"
                              onChange={handleChange}
                              value={values.contact_group}
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                  width:'100%'
                                },
                              }}
                            >
                              <MenuItem value="" disabled selected>Selectionner Votre Group</MenuItem> 
                              <MenuItem value="GROUP_COMMERCIAL">Commercial & prospect</MenuItem>
                              <MenuItem value="GROUP_SUPLIER">Fournisseur</MenuItem>
                              <MenuItem value="GROUP_CONDIDAT">Candidat de recrutement</MenuItem>
                              <MenuItem value="GROUP_STUDENT">Etudiant</MenuItem>
                            </Field>
                            <ErrorMessage name="contact_group" component={MzErrorText} />
                          </Box>

                        { value === false && <>

                          {/* prenom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Prénom*</label>
                            <Field
                              name="first_name"
                              as={TextField}
                              variant="outlined"
                              placeholder="Prénom"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="first_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* nom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Nom*</label>
                            <Field
                              name="last_name"
                              as={TextField}
                              variant="outlined"
                              placeholder="Nom"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="last_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* phone */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Numéro de téléphone*</label>
                            <Field
                              name="phone"
                              as={TextField}
                              variant="outlined"
                              placeholder="+213 000 00 00 00"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="phone"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* School */}
                          {values.contact_group === 'GROUP_STUDENT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>École</label>
                              <Field
                                name="school"
                                as={TextField}
                                variant="outlined"
                                placeholder="Nom de l'école"
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                  },
                                }}
                              />
                              <ErrorMessage
                                name="school"
                                component={MzErrorText}
                              />
                            </Box>
                          )}

                          {/* Pre notification */}
                          {values.contact_group === 'GROUP_CONDIDAT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>Pré-notification</label>
                              <Field
                                name="pre_notification"
                                as={Select}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.pre_notification}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                    width: '100%'
                                  },
                                }}
                              >
                                <MenuItem value="" disabled selected>Selectionner une durée</MenuItem>
                                <MenuItem value="15 jours">15 jours</MenuItem>
                                <MenuItem value="1 mois">1 mois</MenuItem>
                                <MenuItem value="2 mois">2 mois</MenuItem>
                                <MenuItem value="3 mois ou plus">3 mois ou plus</MenuItem>
                              </Field>
                              <ErrorMessage name="pre_notification" component={MzErrorText} />
                            </Box>
                          )}

                          {/* Brochure */}
                          { (values.contact_group === 'GROUP_SUPLIER' || values.contact_group === 'GROUP_CONDIDAT') && <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>Votre Brochure*</label>
                            <Button
                              component="label"
                              role={undefined}
                              sx={{'width':'100%'}}
                              variant="upload"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  setFieldValue('brochure', file);
                                  setSelectedFileName(file.name);
                                }
                              }}
                            >
                              {selectedFileName ? selectedFileName : 'Upload'}
                              <VisuallyHiddenInput type="file" />
                            </Button>
                          </Box>}

                          {/* more */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <List>
                              <ListItem onClick={toggleCollapse} sx={{cursor:'pointer'}}>
                                <Typography variant="p" fontSize={'.8rem'} color={colors.gray}>
                                  Afficher plus de détails
                                </Typography>
                                {isCollapsed ? <Add sx={{'color':colors.gray}} /> : <ExpandLess sx={{'color':colors.gray}} />}
                              </ListItem>
                              <Collapse sx={{width:'100%', margin:0, padding:0}} in={!isCollapsed} timeout="auto" unmountOnExit>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'center', gap:1}}>
                                <label style={{fontSize:15}}>Email (optionnel)</label>
                                <Field
                                  name="email"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="example@email.com"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15}}>Nom de l'entreprise (optionnel)</label>
                                <Field
                                  name="company_name"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="Mon Entreprise"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15}}>Fonction (optionnel)</label>
                                <Field
                                  name="function"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="ex: CEO"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                              </Collapse>
                            </List>
                          </Box>

                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                            En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>

                          {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">Veuillez remplir les champs obligatoire </p>}
                          </>
                        }

                        { value === true && <>
                          {/* email */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Email*</label>
                            <Field
                              name="email"
                              as={TextField}
                              variant="outlined"
                              placeholder="example@email.com"
                              size="small"
                              fullWidth
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">{ErrorMsg}</p>}
                          </Box>

                          {/* School */}
                          {values.contact_group === 'GROUP_STUDENT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>École</label>
                              <Field
                                name="school"
                                as={TextField}
                                variant="outlined"
                                placeholder="Nom de l'école"
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                  },
                                }}
                              />
                              <ErrorMessage
                                name="school"
                                component={MzErrorText}
                              />
                            </Box>
                          )}

                          {/* Pre notification */}
                          {values.contact_group === 'GROUP_CONDIDAT' && (
                            <Box
                              marginRight="1rem"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1.3rem",
                                gap: 1
                              }}
                            >
                              <label style={{ fontSize: 15, color: colors.carbon_black }}>Pré-notification</label>
                              <Field
                                name="pre_notification"
                                as={Select}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.pre_notification}
                                size="small"
                                InputProps={{
                                  style: {
                                    backgroundColor: '#F5F5F5',
                                    width: '100%'
                                  },
                                }}
                              >
                                <MenuItem value="" disabled selected>Selectionner une durée</MenuItem>
                                <MenuItem value="15 jours">15 jours</MenuItem>
                                <MenuItem value="1 mois">1 mois</MenuItem>
                                <MenuItem value="2 mois">2 mois</MenuItem>
                                <MenuItem value="3 mois ou plus">3 mois ou plus</MenuItem>
                              </Field>
                              <ErrorMessage name="pre_notification" component={MzErrorText} />
                            </Box>
                          )}

                          {/* Brochure */}
                          {(values.contact_group === 'GROUP_SUPLIER' || values.contact_group === 'GROUP_CONDIDAT') && <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>Votre Brochure*</label>
                            <Button
                              component="label"
                              role={undefined}
                              sx={{'width':'100%'}}
                              variant="upload"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  setFieldValue('brochure', file);
                                  setSelectedFileName(file.name);
                                }
                              }}
                            >
                              {selectedFileName ? selectedFileName : 'Upload'}
                              <VisuallyHiddenInput type="file" />
                            </Button>
                          </Box>}
                          
                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent:'start',
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                              En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>
                          </>
                        }

                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              flexDirection: "column",
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                { sending ?
                                <CircularProgress/>
                                :
                                <Button
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    lg: "100%",
                                  },
                                  backgroundColor:'#964CFC',
                                  border: 'none',
                                  borderRadius: '16px',
                                  padding: '10px 0px',
                                  fontWeight:500
                                }}
                                disabled={sending}
                                variant="primary"
                                type="submit"
                              >
                                Envoyer
                              </Button> 
                                }
                            </Box>
                          </Box>
                        </Box>
                        </Form>
                    )}
          </Formik>
        </Container>
      </Modal>
  );
}

export default ContactModal;
