import React, { useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography, MenuItem, IconButton, Select, FormControl, InputLabel, useMediaQuery, useTheme, Avatar, Modal, CircularProgress } from '@mui/material';
import * as Yup from 'yup';
import api from '../../api/Api_1_3';
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MzErrorText from '../../components/ui/MzErrorText';
import MyDPFullDate from '../../components/ui/MyDPFullDate';
import Cropper from 'react-easy-crop';
import MzLabel from '../../components/ui/MzLabel';
import { colors } from '../../theme/Colors';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import getCroppedImg from "../../utils/cropImage";
import defaultLogo from "../../imgs/logo.png";

const FillProfile = ({id}) => {
    const navigate = useNavigate();
    const minDate = new Date();

    // Cover Section

const [coverImage, setCoverImage] = useState();
const [onCoverImage, setOnCoverImage] = useState(false);
const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
const [coverZoom, setCoverZoom] = useState(1);
const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
const [coverFormat, setCoverFormat] = useState("");
const [coverProgress, setCoverProgress] = useState(0);
const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
const coverImageProfile = useRef();
const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };


const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
  setCoverCroppedAreaPixels(croppedAreaPixels);
};

const onCoverCrop = async () => {
  const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
  setCoverImage(URL.createObjectURL(croppedImage));
  var file = new File([croppedImage], `${Math.floor(Math.random() * 10000000000000)}.${coverFormat.split("/")[1]}`, { lastModified: 1534584790000, type: [`${coverFormat}`] });
  if (file && croppedImage) {
    setOnCoverImage(false);
    formikRef.current.values.cover_picture = file;
    file = null;
  }
};


const theme = useTheme();
const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

const getAvatarStyle = () => {
  let width;

  if (isXsScreen || isSmScreen) {
    width = '100%';
  } else if (isMdScreen) {
    width = '45.3125rem'; // 725px
  } else if (isLgScreen) {
    width = '45.3125rem'; // 725px
  }

  return {
    width
  };
};


const formikRef = useRef();

// End: Cover Section

// Profile Picture Section
const [image, setImage] = useState(defaultLogo);
const [format, setFormat] = useState("");
const [onImage, setOnImage] = useState(false);
const [crop, setCrop] = useState({ x: 0, y: 0 });
const [zoom, setZoom] = useState(1);
const [progress, setProgress] = useState(0);
const [croppedAreaPixels, setCroppedAreaPixels] = useState();
const onCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};
const onCrop = async () => {
  const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
  setImage(URL.createObjectURL(croppedImage));
  var file = new File(
    [croppedImage],
    `${Math.floor(Math.random() * 100000000000000)}.${
      format.split("/")[1]
    }`,
    { lastModified: 1534584790000, type: [`${format}`] }
  );
  if (file && croppedImage) {
    setOnImage(false);
    formikRef.current.values.profile_picture = file;
  }
};

const [onUploadImage, setOnUploadIage] = useState(false);


const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        sex: Yup.string().required('Sex is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone_number: Yup.number().required('Phone number is required'),
        function: Yup.string().required('Function is required'),
        // Other fields are optional
});

// END Profile Picture Section

const handleSubmit = async (values, { setSubmitting }) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            if (values[key]) { // Only append if the value is not empty
                formData.append(key, values[key]);
            }
        });
        
        try {
           const res = await api.post('/customers/fill-empty-profile/' + id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params:{
                    _method:'PATCH'
                }
            });
            if(res.status === 200){
                toast.success('Customer Ajouteé avec succès');
                setTimeout(()=>{
                  window.location.reload()
                },1000)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setSubmitting(false);
        }
};

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            my: '2rem', 
            width: { lg: '50%', xs: '100%' }, 
            mx: {
                xs:0,
                sm:0,
                md:'auto',
                lg:'auto',
                xl:'auto'
            },
            px:2
        }}>
            <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Typography variant='h5' mb={4} alignSelf={'center'}>Remplir Votre Profile</Typography>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    first_name: '',
                    last_name: '',
                    sex: '',
                    email: '',
                    phone_number: '',
                    function: '',
                    country_name: '',
                    city_name: '',
                    postal_code: '',
                    address: '',
                    profile_picture: '',
                    cover_picture: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, values, setFieldValue, errors }) => (
                    <Form>
                        <Box display={'flex'} flexDirection={{
                            xs:'column',
                            sm:'column',
                            md:'column',
                            lg:'row',
                            xl:'row'
                        }}
                        alignItems={'start'}
                        gap={2}
                        >
                            {/* Profile Picture */}
                         <Box display="flex" flexDirection="column"  >
                    <MzLabel>Photo de Profile </MzLabel>
                    <Avatar
                      sx={{
                        position: "relative",
                        borderRadius: "20px",
                        width: "12rem",
                        height: "12rem",
                        border: "1px solid #C9C5D2",
                        marginBottom: "1rem",
                      }}
                      variant="rounded"
                    >
                      {image ? (
                        <img
                          src={image}
                          alt="The current file"
                          style={{ width: "13rem" }}
                        />
                      ) : (
                        <img
                          src={values.profile_picture}
                          alt="The current file"
                          style={{ width: "13rem" }}
                        />
                      )}
                      <IconButton
                        sx={{
                          zIndex: "33",
                          position: "absolute",
                          right: "0.5rem",
                          bottom: "0.5rem",
                          bgcolor: `${colors.main_purple}`,
                          "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                        }}
                        component="label"
                      >
                        <input
                          hidden
                          id="profile_picture"
                          name="profile_picture"
                          type="file"
                          onChange={(event) => {
                            setFieldValue(
                              "profile_picture",
                              event.target.files[0]
                                ? event.target.files[0]
                                : null
                            );
                            if (event.target.files[0]) {
                              setOnImage(true);
                              setImage(
                                URL.createObjectURL(event.target.files[0])
                              );
                              setFormat(event.target.files[0].type);
                            }
                          }}
                        />
                        <AddRoundedIcon htmlColor="white" />
                      </IconButton>
                    </Avatar>
                    <Modal
                      sx={{ backgroundColor: "#3f3f3f" }}
                      open={onImage}
                      onClose={() => {
                        setImage(null);
                        formikRef.current.values.profile_picture = "";
                        setOnImage(false);
                      }}
                    >
                      <Box height="100%" width="100%" margin="0 auto">
                        <Box
                          sx={{
                            height: "80%",
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                          >
                            <Cropper
                              style={styleCropper}
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={1 / 1}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </Box>
                        </Box>
                        <Box
                          position="absolute"
                          bottom="5%"
                          left="0"
                          right="0"
                          gap="1rem"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MzErrorText>
                            {errors.profile_picture ? errors.profile_picture : null}
                          </MzErrorText>
                          <Box
                            width="fit-content"
                            display="flex"
                            flexDirection="row"
                            gap="1rem"
                            alignItems="center"
                          >
                            <Button
                              disabled={errors?.profile_picture ? true : false}
                              onClick={onCrop}
                              variant="primary"
                            >
                              Confirmer
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setImage(null);
                                formikRef.current.values.profile_picture = "";
                                setOnImage(false);
                              }}
                            >
                              Annuler
                            </Button>
                          </Box>
                          <Box
                            display="flex"
                            gap="0.5rem"
                            sx={{
                              flexDirection: {
                                xs: "column",
                                md: "row",
                                lg: "row",
                                sm: "row",
                              },
                              alignItems: "center",
                            }}
                          >
                            <Typography color="white">
                              Niveau de zoom :{" "}
                            </Typography>
                            <input
                              type="range"
                              min={1}
                              max={3}
                              step={0.1}
                              value={zoom}
                              onInput={(e) => setZoom(e.target.value)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                    <ErrorMessage name="profile_picture" component={MzErrorText} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                    >
                      <Box>
                        {onUploadImage && (
                          <Box display="flex" alignItems="center" gap="1rem">
                            <CircularProgress size={30} />
                            <Typography width="20px" color={colors.main_purple}>
                              {progress ? progress + "%" : null}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                         </Box>

                         {/* Cover Picture Section */}
                        <Box display="flex" flexDirection="column">
                      <MzLabel>Coverture de l'entreprise </MzLabel>
                        <Avatar
                          sx={[getAvatarStyle(),{
                            position: "relative",
                            borderRadius: "20px",
                            height: "14rem", // 224px
                            border: "1px solid #C9C5D2",
                            marginBottom: "1rem",
                          }]}
                          variant="rounded"
                        >
                          {coverImage ? (
                              <img alt="cover-pic" ref={coverImageProfile} src={coverImage} style={{ width: "100%", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                            ) : (
                              <img alt="cover-pic" src={'https://placehold.co/725x224'} style={{ width: "45.3125rem", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                          )}                          
                          <IconButton
                            sx={{
                              zIndex: "33",
                              position: "absolute",
                              right: "0.5rem",
                              bottom: "0.5rem",
                              bgcolor: `${colors.main_purple}`,
                              "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                            }}
                            component="label"
                          >
                            <input
                              hidden
                              id="cover_picture"
                              name="cover_picture"
                              type="file"
                              onChange={(event) => {
                                setFieldValue("cover_picture", event.target.files[0] ? event.target.files[0] : null);
                                if (event.target.files[0]) {
                                  setOnCoverImage(true);
                                  setCoverImage(URL.createObjectURL(event.target.files[0]));
                                  setCoverFormat(event.target.files[0].type);
                                }
                              }}
                            />
                            <AddRoundedIcon htmlColor="white" />
                          </IconButton>
                        </Avatar>
                        <Modal
                          sx={{ backgroundColor: "#3f3f3f" }}
                          open={onCoverImage}
                          onClose={() => {
                            setCoverImage(null);
                            formikRef.current.values.cover_picture = "";
                            setOnCoverImage(false);
                          }}
                        >
                          <Box height="100%" width="100%" margin="0 auto">
                            <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <Box height="100%" width="100%" display="flex" flexDirection="column">
                                <Cropper style={styleCropper} image={coverImage} crop={coverCrop} zoom={coverZoom} aspect={725 / 224} onCropChange={setCoverCrop} onCropComplete={onCoverCropComplete} onZoomChange={setCoverZoom} />
                              </Box>
                            </Box>
                            <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                              <MzErrorText>{errors.cover_picture ? errors.cover_picture : null}</MzErrorText>
                              <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                <Button disabled={errors?.cover_picture ? true : false} onClick={onCoverCrop} variant="primary">
                                  Confirmer
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    setCoverImage(null);
                                    formikRef.current.values.cover_picture = "";
                                    setOnCoverImage(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Box>
                              <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                <Typography color="white">Niveau de zoom : </Typography>
                                <input type="range" min={1} max={3} step={0.1} value={coverZoom} onInput={(e) => setCoverZoom(e.target.value)} />
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                        <Box>
                          {errors.cover_picture ? <MzErrorText>{errors.cover_picture}</MzErrorText> : null}
                        </Box>

                        <Box display="flex" flexDirection="column" alignItems="end" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                          <Box>
                            {onUploadCoverImage && (
                              <Box display="flex" alignItems="center" gap="1rem">
                                <CircularProgress size={30} />
                                <Typography width="20px" color={colors.main_purple}>
                                  {coverProgress ? coverProgress + "%" : null}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        </Box>
                        </Box>

                        {/* Customer details */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="first_name"
                                    as={TextField}
                                    label="Prénom"
                                    fullWidth
                                    size="small"
                                />
                                <ErrorMessage name="first_name" component={MzErrorText} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="last_name"
                                    as={TextField}
                                    label="Nom"
                                    fullWidth
                                    size="small"
                                />
                                <ErrorMessage name="last_name" component={MzErrorText} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="email"
                                    as={TextField}
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    size="small"
                                />
                                <ErrorMessage name="email" component={MzErrorText} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="sex-label">Sex</InputLabel>
                                    <Field
                                        name="sex"
                                        as={Select}
                                        labelId="sex-label"
                                        label="Sexe"
                                        onChange={(event) => setFieldValue("sex", event.target.value)}
                                    >
                                        <MenuItem value="MALE">MALE</MenuItem>
                                        <MenuItem value="FEMALE">FEMALE</MenuItem>
                                    </Field>
                                </FormControl>
                                <ErrorMessage name="sex" component={MzErrorText} />
                            </Grid>
                            {/* Other fields remain optional */}
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="phone_number"
                                    as={TextField}
                                    label="Téléphone"
                                    fullWidth
                                    size="small"
                                />
                                <ErrorMessage name="phone_number" component={MzErrorText} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="address"
                                    as={TextField}
                                    label="Address"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="city_name"
                                    as={TextField}
                                    label="Ville"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="country_name"
                                    as={TextField}
                                    label="Pays"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="postal_code"
                                    as={TextField}
                                    label="Code Postal"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="function"
                                    as={TextField}
                                    label="Fonction"
                                    fullWidth
                                    size="small"
                                />
                                <ErrorMessage name="function" component={MzErrorText} />
                            </Grid>
                        </Grid>

                        {/* Create Action */}
                        <Box display={'flex'} mt={2} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                Remplir le Profile
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => navigate('/admin/gestion-des-customers')} // Navigate to the desired route
                            >
                                Annuler
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default FillProfile;