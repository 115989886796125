import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Api from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./styles/StyledTable.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress, TextField } from "@mui/material";
import API from "../../api/Api_1_3";

function Service() {

  const { user } = useAuthContext();
  const access_token = user.access_token;
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const serviceId = pathname[5];
  const pageId = pathname[3]
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const [cardTheme,setCardTheme] = useState("")
  const [imgUrl,setImgUrl] = useState("")
  const [fileUrl,setFileUrl] = useState("")
  const [types, setTypes] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    url: "",
    file: "",
    image: "",
    display_type: "EXTERNAL",
    description: "",
    status:"",
  });
  const [brochureTypeId, setBrochureTypeId] = useState(formData.brochure_type_id);
  const [nbrPages, setNbrPages] = useState(formData.brochure_page_nbr);

  async function getService() {
    try {

      if(serviceId){
        const response = await Api.get(`/services/${serviceId}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      });

      setFormData(response.data?.service ?? []);
      setImgUrl(response.data?.service?.url_image)
      setFileUrl(response.data?.service?.url_file)
      setNbrPages(response.data.service?.brochure_page_nbr)
      setBrochureTypeId(response.data?.service?.brochure_type.id)
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  async function getPage() {
    try {
      const pathname = location.pathname.split("/");
      const pageId = pathname[3];

      const response = await Api.get(`/pages/${pageId}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      });
      setCardTheme(response.data.page?.card_theme)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getService();
    getPage();
    getTypes()
  }, [serviceId,access_token]);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

  if (file && file.size > maxSize) {
    // Show toast error
    toast.error("File size exceeds 5MB limit");
    // Clear the file input
    event.target.value = null;
    // Reset the form data
    setFormData({ ...formData, file: null });
  } else {
    setFormData({ ...formData, file });
  }
  };

  const handleImageChange = (event) => {
    setFormData({
      ...formData,
      image: event.target.files[0],
    });
  };

  const getTypes = () => {
    API.get('/setting/brochure-types', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
        .then((response) => {
          setTypes(response.data.data);
        })
        .catch((error) => {
            if (error.response.status === 403) toast.error("Une erreur est survenue");
            else toast.error(error.response.data.message);
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
            }
        });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if((formData.url === "" || formData.url == null) && (formData.file === "" || formData.file === null)){
      toast.error('Veuillez Au minimum ajouter un lien ou une brouchure !')
      setLoading(false)
      return;
    }
    if(formData.url_file || formData.url_image){
      delete formData.url_file && delete formData.url_image
    }
    const pathname = location.pathname.split("/");
    const pageId = pathname[3];

    const data = new FormData();
    if(!serviceId || serviceId === undefined){
      data.append("page_id", pageId);
      delete formData.status
    }
    else if(serviceId !== null || serviceId !== ""){
      data.append('status',formData.status)
      data.delete('page_id')
    }
    
    data.append("name", formData.name);
    
    data.append("display_type", formData.display_type);

    if(formData.description === "" || formData.description === null){
      delete formData.description
      data.delete("description");
    }
    else {
      data.append("description", formData.description);
    }

    if(formData.url === "" || formData.url === null){
      delete formData.url
      data.delete("url");
    }
    else {
      data.append("url", formData.url);
    }

    if(!formData.file ){
      delete formData.file
    }
    else {
      data.append("file", formData.file);
    }

    if(!formData.image ){
      delete formData.image
    }
    else {
      data.append("image", formData.image);
    }

    if(formData.file){
      data.append('brochure_type_id', brochureTypeId)
      data.append('brochure_page_nbr', nbrPages)
    }
    try {
      const response = await Api.post(
        serviceId ? "/services/update/" + serviceId : "/services",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200) {
        // Handle success, e.g., show a success message or redirect
        toast.success("Operation success");
        setTimeout(() => {
          navigate("/admin/" + pageId + "/services");
        }, 2000);
      } else {
        // Handle errors, e.g., display an error message
        toast.error("Operation echoué");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setLoading(false)
  };

  return (
    <div>
      <ToastContainer
          style={{ top: "5rem", width: "300px" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      <div className="flex items-center gap-3 w-full mb-6">
      <h1 className="text-xl font-bold">{serviceId ? 'Modifier' : 'Ajouter '} un service ou un produit </h1>
      </div>
      <form onSubmit={handleSubmit} className="table-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            Nom de service *
          </label>
          <input
            placeholder="Nom de service"
            name="name"
            className="default-input"
            value={formData.name}
            onChange={handleTextChange}
            required
          />
        </div>

        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            URL de service
          </label>
          <input
            placeholder="URL de service"
            name="url"
            className="default-input"
            value={formData.url}
            onChange={handleTextChange}
          />
        </div>
        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            Type d'affichage *
          </label>
          <select
            name="display_type"
            className="default-input"
            onChange={handleTextChange}
            value={formData.display_type}
            required
          >
            <option value={"EXTERNAL"}>Exterieur</option>
            <option value={"INTERNAL"}>Interieur</option>
          </select>
        </div>
       {serviceId ?  <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            Status
          </label>
          <select
            name="status"
            className="default-input"
            onChange={handleTextChange}
            value={formData.status}
            required
          >
            <option value={"ACCEPTED"}>Active</option>
            <option value={"BLOCKED"}>Inactive</option>
          </select>
        </div>
        : null}
        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>Brochure (5MB maximum)</label>
          <input
            type="file"
            name="file"
            className="default-input"
            onChange={handleFileChange}
          />
          { !formData.file && (fileUrl && serviceId) ? <a href={fileUrl} target="_blank" rel="noreferrer" className="my-1 flex items-center gap-1 current_link font-medium">
            <p className="text-sm underline bg-transparent underline-offset-2">Consulter l'actuelle</p>
            <OpenInNewIcon sx={{'fontSize':'large'}}/>
            </a> : null}
        </div>

      {/* Conditional brochure type and pages number rendering */}
     { (fileUrl || formData.file) && 
     <>
      <div 
        style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "row",
            gap:12
      }}>
        <section className="flex flex-col w-full">
        <label style={{ fontSize: 14, fontWeight: 700 }} htmlFor="format-select">Type de brochure</label>
        <select
          id="format-select"
          name="brochure_type_id"
          className="default-input"
          value={brochureTypeId}
          required
          onChange={(event) => setBrochureTypeId(event.target.value)}
        >
          {types.map((type) => (
            <option key={type.id} value={type.id}>
              {type.format} - ({type.grammage}g/m²)
            </option>
          ))}
        </select>
        </section>

        <section className="flex flex-col w-full">
        <label style={{ fontSize: 14, fontWeight: 700 }} htmlFor="nbr_pages">Nombre Du Pages</label>
        <input
          id="nbr_pages"
          name="nbr_pages"
          placeholder="ex: 25"
          className="default-input"
          type="number"
          required
          value={nbrPages}
          onChange={(event) => setNbrPages(event.target.value)}
          style={{ width: '100%' }}
        />
        </section>
      </div>
     </>
      }

        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="imgUrl" style={{ fontSize: 14, fontWeight: 700 }}>
            Image de service * (recommandé {cardTheme === 'TYPE2' ? '500 x 600' : '500 x 500'} px ) 
          </label>
          <input
            type="file"
            name="image"
            id="imgUrl"
            className="default-input"
            onChange={handleImageChange}
            required={serviceId ? false : true}
          />

         { !formData.image && serviceId ? <NavLink to={imgUrl} target="_blank" className="my-1 flex items-center gap-1 font-medium">
            <p className="text-sm underline underline-offset-2">Consulter l'actuelle</p>
            <OpenInNewIcon sx={{'fontSize':'large'}}/>
            </NavLink> : null}
            
        </div>

        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ fontSize: 14, fontWeight: 700 }}>
            La Description de service
          </label>
          <textarea
            placeholder="La description de service"
            name="description"
            rows={10}
            className="default-input"
            value={formData.description}
            onChange={handleTextChange}
          />
        </div>

        <div className=" flex justify-center md:justify-end lg:justify-end items-center w-full mt-16 gap-4">
            {loading === true ? <CircularProgress/> : <button
              type="submit"
              className="bg-[#7B3FFF] px-4 py-2 text-white rounded-lg"
            >
              Enregistrer
          </button>}
            <NavLink
                to={"/admin/" + pageId + "/services" }
                style={{ backgroundColor: "#9A9A9A", width: "auto" }}
                className=" px-4 py-2 text-white rounded-lg"
            >
                Annuler
            </NavLink>
                    
        </div>

      </form>
    </div>
  );
}

export default Service;
