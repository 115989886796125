import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate, useLocation } from "react-router-dom";

export const useLogin = (email, password) => {
  const [errorLogin, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    //remove const response = await
    const response = await Api.post(
      Urls.LOGIN_URL,
      { email, password },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          // save the user to local storage
          localStorage.setItem("user", JSON.stringify(response.data));
          if(response.data?.user?.is_first_login === 1){
            navigate('/reset-password-for-the-first-time')
            return;
          }

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });
          
          // update loading state
          setTimeout(() => {
            setIsLoading(false);
            window.location.href = response.data?.user?.role === 'COLLABORATOR' ? '/abonnee' : (response.data?.user?.role === 'PROSUMER' || response.data?.user?.role === 'CONSUMER') ? '/account' : '/admin'
          }, 1000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.response.data.message);
      });
  };

  return { login, isLoading, errorLogin };
};
