import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import API from "../../api/Api";
import { useNavigate } from "react-router-dom";
import { Urls } from "../../api/Urls";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { Button, Box, Typography, Modal } from "@mui/material"; // Added missing imports
import CreateProfile from "../admin/CreateProfile";

const AbonneeProfiles = () => {
    const [data, setData] = useState();
    const [profiles, setProfiles] = useState([])
    const user = JSON.parse(localStorage.getItem("user"));
    const access_token = user.access_token;
    const id = user.user.id;
    const navigate = useNavigate();
    const [openCreateProfile, setOpenCreateProfile] = useState(false); // New state for modal
    const [change, setChange] = useState(false)
    const [didUpdate, setDidUpdate] = useState(false);

    const handleCloseCreateProfile = () => {
        setOpenCreateProfile(false);
        getUser()
      };

      const getUser = ()=>{
        API.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}/account-details`, {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              setData(response.data.user);
              setProfiles(response.data.user.profiles)
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              if (error.response.status === 403) toast.error("Une erreur est survenue");
              else toast.error(error.response.data.message);
            });
      }

    useEffect(() => {
        getUser()
      }, [didUpdate, change, access_token, id, navigate]);

      const profileColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { 
          field: 'business_commercial_name',
          headerName: 'Nom Commercial',
          width: 150,
          renderCell: (params) => (
            <Button onClick={() => navigate(`/account/${id}/profiles/${params.row.id}`, { state: { source: 'account' } })}>
                {params.value}
            </Button>
        )
        },
        { field: 'phone_number', headerName: 'Téléphone', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'function', headerName: 'Fonction', width: 150 },
        { field: 'country_name', headerName: 'Pays', width: 150 },
        { field: 'city_name', headerName: 'Ville', width: 150 },
        { field: 'postal_code', headerName: 'Code Postal', width: 150 },
        { field: 'address', headerName: 'Adresse', width: 200 },
      ];



    return ( 
        <Box>
            <ToastContainer
              style={{ top: "5rem" }}
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
        {
        data?.can_have_multiple_profiles === true &&
        <>
        {/* Profiles Section */}
        <Box display={'flex'} alignItems={'end'} justifyContent={'space-between'}>
        <Typography variant='h6' sx={{ marginTop: "2rem" }}>Liste des Profils</Typography>
        {profiles.length < 3 && 
          <Button
          variant="primary"
          onClick={() => setOpenCreateProfile(true)} // Open modal on button click
          >
          Créer un nouveau profil
          </Button>
        }
        </Box>
        <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          rows={profiles}
          columns={profileColumns}
          pageSize={5}
        />
        </Box>

        {/* Modal for CreateProfile */}
        <Modal open={openCreateProfile} onClose={() => setOpenCreateProfile(false)}>
          <Box sx={{
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh', // Limit height for scrolling
            overflowY: 'auto', // Enable vertical scrolling
            width: { xs: '90%', sm: '80%', md: '80%', lg: '80%' }, // Responsive width
            margin: 'auto', // Center the modal
          }}>
            <CreateProfile id={id} onClose={handleCloseCreateProfile} />
          </Box>
        </Modal>
        {/* End Profiles Section */}
        </>
      }
        </Box>
     );
}
 
export default AbonneeProfiles;